import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../styledComponents';
import downIcon from '../../../assets/faq/downIcon.svg';
import upIcon from '../../../assets/faq/upIcon.svg';

const Container = styled.div`
  position: relative;
  left: 29px;
  outline: none;
  cursor: ${({ alwaysOpened }) => alwaysOpened ? 'default' : 'pointer'};

  @media (max-width: ${mobileThresholdPixels}) {
    left: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
    padding-right: ${({ alwaysOpened }) => alwaysOpened ? '0' : '29'}px;
  }
`;

const QuestionTitle = styled.h4`
  color: ${colors.navy};
  font-family: Gotham;
  font-size: 22px;
`;

const Answer = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  margin: 0px;
`;

const SubAnswer = styled(Answer)`
  margin-left: 20px;
`;

const Icon = styled.img`
  position: absolute;
  left: -29px;
  top: 7px;
`;

const Link = styled.span`
  text-decoration: underline;
`;

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.alwaysOpened,
    };
    this.toggle = this.toggle.bind(this);
    this.renderAnswer = this.renderAnswer.bind(this);
  }

  toggle() {
    const { alwaysOpened } = this.props;
    if (alwaysOpened) return;
    this.setState({ isOpened: !this.state.isOpened });
  }

  renderAnswer(answer) {
    const { openPopup } = this.props;
    if (answer === 'POPUP') {
      return (<Answer key={answer}>
        {'- Vous voulez savoir si Tilli se déplace chez vous ? '}
        <Link onClick={openPopup}>
          Testez votre code postal
        </Link>
      </Answer>);
    }
    if (typeof answer === 'object') {
      return (
        <React.Fragment>
          <Answer key={answer.text}>- {answer.text}</Answer>
          {answer.subAnswers.map(subAnswer => (
            <SubAnswer key={subAnswer}>- {subAnswer}</SubAnswer>
          ))}
        </React.Fragment>
      );
    }
    return (
      <Answer key={answer}>- {answer}</Answer>
    );
  }

  render() {
    const { question, alwaysOpened } = this.props;
    const { isOpened } = this.state;
    return (
      <Container key={question.question} onClick={this.toggle} alwaysOpened={alwaysOpened}>
        {!alwaysOpened && !isOpened && <Icon src={downIcon} />}
        {!alwaysOpened && isOpened && <Icon src={upIcon} />}
        <QuestionTitle>{question.question}</QuestionTitle>
        {question.answers.length === 1 && isOpened && <Answer>{question.answers[0]}</Answer>}
        {question.answers.length > 1 && isOpened &&
          question.answers.map(this.renderAnswer)
        }
      </Container>
    );
  }
}


Question.propTypes = {
  question: PropTypes.shape({}).isRequired,
  alwaysOpened: PropTypes.bool,
  openPopup: PropTypes.func,
};

Question.defaultProps = {
  alwaysOpened: false,
  openPopup() {},
};

export default Question;
