/* eslint-disable max-len */
export const mostFrequentQuestions = [
  { question: 'Nos couturiers - tous modélistes - on s’adapte aux coupes et aux asymétries !',
    answers: ['Nos Tillistes sont des couturiers modélistes avec un regard stylistique sélectionnés avec soin sur leurs savoirs faire et leur savoir être. On les adore, vos clients vont les adorer aussi ;) La retouche ne sera plus une corvée pour vous clients mais un moment humain et agréable avec son couturier sur-mesure.',
    ] },
  { question: 'Quels sont les délais ?',
    answers: ['3 à 5 jours en général',
      'Le client fixera la date de rendu avec son couturier en RDV :)',
      'Pour toute demande urgente, il peut nous contacter au 01.84.80.05.39, nous ferons le maximum !'] },
  { question: 'Où vous déplacez-vous ?',
    answers: [
      'Nous nous déplaçons à Paris et sa proche banlieue, Lyon, Marseille, Aix en Provence, Bordeaux, Toulouse, Lille, Rennes, Nantes, Strasbourg, Nice, Cannes, Montpellier, Nimes, Metz, Grenoble, Caen, Rouen et Tours.',
      'POPUP',
      '“Mon client habite-t-il à moins de 5 minutes à pied d’un arrêt de métro/RER ?” OUI - alors on y va !'] },
  {
    question: 'Estimation du devis - Matières simples ou complexes ?',
    answers: ['Simple/basique : jeans, chino, coton, polyester…',
      'Complexe/habillé : Dentelle, soie, costumes, cuir, velours, viscose….',
      'Si le vêtement passe en machine classique, c’est du basique; si vous le lavez en délicat ou pressing c’est du habillé ;)'] },
  {
    question: 'Paiement des retouches',
    answers: ['Vous n’avez rien à faire, on s’occupe de tout dès la prise de commande.'],
  },
];

export const serviceQuestions = [
  { question: 'Tilli, qu’est-ce que c’est ?',
    answers: [
      'Tilli, c‘est le premier service de couturiers à domicile. Un service vous permettant de bichonner votre client dans le prolongement de son expérience boutique. À partir de maintenant vous ne laissez plus votre client face à un problème de fit, vous l’accompagnez jusqu’au dernier point de couture.',
    ] },
  { question: 'Comment ça marche ? ',
    answers: [
      '(1) À date et heure de votre choix, nos couturiers se déplacent chez vos clients. Hop, votre client repart avec ses achats et pourra retrousser son jean ce soir en attendant son couturier le lendemain ;)',
      '(2) Le RDV : dure une 30ène de minute pour que votre client puisse prendre le temps avec son couturier d’être conseillé au mieux, d’essayer avec les bonnes chaussures ou le bon t-shirt et valider définitivement les retouches à effectuer pendant son RDV. Validation du devis.',
      '(3) 3 à 5 jours plus tard, le client récupère ses affaires à son domicile.',
    ],
  },
  { question: 'Comment prendre rendez-vous pour mon client ? ',
    answers: [
      'Rien de plus simple ! Cliquez sur « prendre RDV » et suivez les étapes de validation des retouches pour votre client. On vous indiquera un devis à titre indicatif, qui n’est pas figé. Le client pourra toujours ajouter des pièces à retoucher ou à broder pendant son rendez-vous.',
      'Regardez votre vidéo Tutoriel :',
      'Menu > Tutoriel',
      '1 minute 30 après, c’est à vous de jouer !',
    ],
  },
  { question: 'Faire un devis, c’est obligatoire ? ',
    answers: [
      'Non ! Mais on vous le conseille pour orienter votre client ;-)',
      'Pas d’inquiétude, si vous devez aller vite, passez l’étape du devis.',
      'N’oubliez pas en revanche de préciser la matière !  Cela nous permet de vous envoyer le couturier qualifié selon la demande. Chaque matière est spécifique, et nous avons des couturiers aux compétences multiples et très différentes.',
    ],
  },
  { question: 'Qui sont les Tillistes ?',
    answers: [
      'Nos Tillistes sont des couturiers modélistes avec un regard stylistique sélectionnés avec soin sur leurs savoirs faire et leur savoir être. On les adore, vos clients vont les adorer aussi ;) La retouche ne sera plus une corvée pour vous clients mais un moment humain et agréable avec son couturier sur-mesure.',
    ],
  },
  { question: 'Quels sont les délais ? ',
    answers: [
      '3 à 5 jours en général',
      'Le client fixera la date de rendu avec son couturier en RDV :)',
      'Pour toute demande urgente, il peut nous contacter au 01.84.80.05.39, nous ferons le maximum !',
    ],
  },
  { question: 'Où le service Tilli est-il desservi ? ',
    answers: [
      'Paris et sa proche banlieue, Lyon, Villeurbanne, Marseille, Aix en Provence et Bordeaux',
      'POPUP',
      '“Mon client habite-t-il à moins de 5 minutes à pied d’un arrêt de métro/RER ?” OUI - alors on y va !',
    ],
  },
  { question: 'Tarifs',
    answers: [
      'Vous trouverez la liste de nos tarifs dans votre menu à gauche “Nos tarifs”',
      'Seules les retouches les plus demandées y sont précisées, mais vous pourrez trouver tous les prix en cliquant sur "voir tous nos tarifs" sous le tableau.',
    ],
  },
  { question: 'Existe-t-il un minimum de commande ? ',
    answers: [
      'À titre exceptionnel, nous n’appliquons le minimum de commande 20€ habituel pour les commandes en partenariat marque.',
      'Pour vous c’est le prix d’un ourlet - 12€ le minimum ;)',
    ],
  },
  { question: 'Qu’est-il compris dans les tarifs ?',
    answers: [
      'Le déplacement du couturier au domicile du client, la réalisation des retouches et la livraison des articles',
      '+ un rendez-vous de réajustement si besoin',
    ],
  },
  { question: 'Comment encaisser mon client ?',
    answers: [
      'PAS d’encaissement de votre côté !!!',
      'On gère à partir de votre prise de commande.',
    ],
  },

  { question: 'Quels sont les moyens de paiement pour le client après ?',
    answers: [
      'Paiement en ligne sécurisé envoyé par mail à la suite de votre prise de RDV pour votre client. Moderne & simple ;)',
      'Si question du client, nous sommes toujours et encore joignable au 01.84.80.05.39',
    ],
  },
];

export const customerCareQuestions = [
  { question: 'Annulations & reports',
    answers: [
      'Le client n‘est pas censé venir vers vous. Le mieux est dans ce cas de lui dire de nous appeler au 01.84.80.05.39 ou de nous envoyer un mail à contact@tilli.fr.',
      'Sachez que nous facturons au client et non à la marque des frais d‘annulation bien spécifié dans le mail de confirmation de sa commande.',
    ],
  },
  { question: 'Modification des coordonnées clients',
    answers: [
      'Idem nous le renvoyer au 01.84.80.05.39 ou par mail.',
      'Sinon il a un joli espace client sur le site tilli.fr et/ou sur l‘App Tilli. ;)',
    ],
  },
  { question: 'Et si mon client a un problème avec les retouches effectuées ?',
    answers: [
      'Toujours pareil, on s’en charge ;)',
      'Un rendez-vous de réajustement est prévu si votre client n’est pas satisfait de ses retouches.',
      'Et si le problème n‘est pas récupérable - ce qui serait très surprenant, nous travaillons avec AXA assurance afin d‘assurer toutes vos pièces.',
    ],
  },
  { question: 'Existe-t-il une application ?',
    answers: [
      'Bien sûr ! Préférez la commande via votre App vendeur, comme ça on sait que cela vient de vous ;',
      'Votre client pourra en parallèle télécharger l’app « Tilli » sur l’Apple Store et sur Google Play et suivre sa commande',
    ],
  },
];
